<template>
  <div class="violations-modal">
    <r-title>{{ title }}</r-title>
    <modal-copy
      :source_id="sourceId"
      :source="source"
      :copy-source="copySource"
    />
  </div>
</template>

<script>
import { titles, sources } from '../../../configs'

export default {
  components: {
    ModalCopy: () => import('./modal-copy')
  },
  props: {
    copySource: {
      type: String,
      required: true
    }
  },
  data: () => ({
    sources,
    titles
  }),
  computed: {
    title() {
      return this.titles.create
    },
    source() {
      return this.$store.state[this.sources.store][this.sources.field]
    },
    sourceId() {
      return this.source?.source_id || null
    }
  }
}
</script>

<style lang="scss" scoped>
.violations-modal {
  display: grid;
  grid-gap: 1rem;
  align-content: start;
}
</style>
